body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.img {
  width: 400px;
  height: 200px;
  object-fit: contain;
}

.footer {
  /* position: absolute; */
  /* bottom: 0; */
  width: 100%;
  padding-top: 4rem;
}

.page-container {
  /* padding-bottom: 27.0rem;    /1* Footer height *1/ */
  position: relative;
  min-height: 100vh;
}

li {
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  list-style-type: circle;
 }
